import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Header, Layout, PreviewCompatibleImage } from "../components";
import { CardWithButton, CardContainer } from "../ui";
import { Wrapper, Container } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/zumba-kuduro-solidaire.jpg";

const CardsList = ({ posts, filter = "action-post" }) => {
  return (
    <CardContainer two>
      {posts
        .filter((post) => post.node.frontmatter.templateKey === filter)
        .map(({ node: post }) => {
          const { id, frontmatter, fields, excerpt } = post;
          const image = frontmatter.thumbnail ? frontmatter.thumbnail : frontmatter.image;
          return (
            <CardWithButton key={id} actionStyle title={frontmatter.title} date={frontmatter.date} text={excerpt} link={fields.slug}>
              <PreviewCompatibleImage imageInfo={image} style={{ height: "100%", borderRadius: "4px 4px 0 0" }} />
            </CardWithButton>
          );
        })}
    </CardContainer>
  );
};

const ActionsPageTemplate = ({ title, posts }) => {
  return (
    <>
      <SEO title={title} />
      <Header title={title} bgImage={bgImage} bgPosition="center 36%" fallback={linearGradients.actions} />
      <Wrapper>
        <Container large>
          <CardsList posts={posts} filter="action-post" />
        </Container>
      </Wrapper>
    </>
  );
};

ActionsPageTemplate.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array,
};

const ActionsPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ActionsPageTemplate title={frontmatter.title} posts={posts} />
    </Layout>
  );
};

ActionsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    markdownRemark: PropTypes.object,
  }),
};

export default ActionsPage;

export const pageQuery = graphql`
  query ActionsPage($id: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "action-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 230)
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            fullDate: date
            date(formatString: "MMMM YYYY", locale: "fr")
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`; /* prettier-ignore */
